export const contributorsIds: number[] = [
  16, // Le Même en Mieux
  53, // Selon Que Choisir
  59, // Selon Le Monde
  91, // Kalivi
  55, // Captain Fact
  80, // Ethi'Kdo
  89, // Biet Thomas
  60, // Amazon Antidote
  25, // Le Même en Local
  24, // Alertoo
  54, // Selon 60M de consommateurs
  96, // Selon Anticor
  116, // Thinkerview addict
  88, // Mastodon
  32, // Colibri pour la planète
  13 // Maarten
];
