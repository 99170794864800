import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 47.683 57.219">
    <g fill="#a4c639">
      <path d="M3.576 19.071A3.581 3.581 0 000 22.647v16.689a3.576 3.576 0 107.152 0V22.647a3.581 3.581 0 00-3.576-3.576z" />
      <path d="M44.107 19.071a3.581 3.581 0 00-3.576 3.576v16.689a3.576 3.576 0 107.152 0V22.647a3.581 3.581 0 00-3.576-3.576z" />
      <path d="M36.955 19.071h-26.23a1.192 1.192 0 00-1.192 1.193v19.073a5.969 5.969 0 004.768 5.841v8.465a3.576 3.576 0 107.152 0v-8.346h4.772v8.345a3.576 3.576 0 107.152 0v-8.465a5.967 5.967 0 004.768-5.841V20.264a1.192 1.192 0 00-1.19-1.193z" />
      <path d="M32.508 4.938l2.9-2.9A1.192 1.192 0 1033.722.352l-3.386 3.386a16.294 16.294 0 00-13 0L13.95.352a1.192 1.192 0 00-1.686 1.686l2.9 2.9a12.578 12.578 0 00-5.639 10.557 1.193 1.193 0 001.192 1.193h26.238a1.193 1.193 0 001.192-1.193 12.578 12.578 0 00-5.639-10.557zm-14.627 6.984a1.192 1.192 0 111.193-1.192 1.192 1.192 0 01-1.193 1.192zm11.921 0a1.192 1.192 0 111.192-1.192 1.193 1.193 0 01-1.192 1.192z" />
    </g>
  </svg>
);
